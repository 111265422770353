import React, { useEffect, useState } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import { useHistory } from "react-router-dom";

import { SettingsContext } from "../settingsContext";
import { LoadingComponent } from "../loading";
import { searchApplication, consoleLogger } from "../../services/utilities";
import { dataServices } from "../../services/dataServices";
import { PermittedUses, OtherPermittedUses } from "../cropPestView";
import { CropSprayerApp, CategoryLink, CategorySection } from ".";
import { StandardTooltipIcon, StandardTooltip } from "../standardTooltip"

import "../../scss/Product.scss";

import {
    IconLabel,
    IconShield,
    IconDocument,
    IconPhone,
    IconEmail,
    IconShieldCheck,
    IconArrowLeft,
    IconArrowRight,
    IconStar,
    IconLocation,
    IconBell
} from "../icons";
import i18n from "../../i18n";

/**
 * Component to render product detail page
 * @param {*} props
 * @returns
 */
function ProductDetails(props) {
    const { t } = props;

    const settings = React.useContext(SettingsContext);

    const history = useHistory();

    // Fix for trailing slash at end
    let search = useLocation().search;
    if (search === "") {
        search = props.location.pathname.substring(
            props.location.pathname.indexOf("?")
        );
    }

    const queryParam = searchApplication.historyHelper.queryParam(search);

    let country = queryParam.country;

    let { id } = useParams();

    var matches = searchApplication.regexProductId(id);

    if (country && country !== "") {
        id = matches[1];
    } else {
        id = matches[1];
        let params = new URLSearchParams(matches[2]);
        country = params.get("country")?.toLowerCase();
    }

    const [appState, setAppState] = useState({
        show: false,
        langOverride: settings.getlng() !== searchApplication.getDocLng(),
        product: {},
    });

    //Get state from route
    var state = props?.location?.state;

    let cropId = state?.searchState?.crop?.value;
    let pestId = state?.searchState?.pest?.value;
    let occupation = state?.searchState?.occupation?.value;

    const addAnalytics = (eventType) => {
        let analyticData = {
            country: country,
            language: settings.getlng(),
            productId: id,
            offline: settings.standalone, //standalone settings value
        };

        if (occupation) {
            analyticData.occupation = occupation;
        }
        if (cropId) {
            analyticData.crop = cropId;
        }
        if (pestId) {
            analyticData.pest = pestId;
        }
        window.cabi_analytics(eventType, analyticData);
    };

    useEffect(() => {
        if (window.cabi_analytics) {
            if (!searchApplication.isCrawler()) {
                addAnalytics("product");
            }
        }

        if (
            settings.hasStaticContent === "true" &&
            window?.productdata?.productDetail?.id === id &&
            settings.getlng() === window.datalng
        ) {
            setAppState({ ...appState, show: true, product: window.productdata });

            //Push GTM
            searchApplication.pushGtmProductDetails(
                window.productdata,
                state?.searchData
            );
        } else {
            dataServices
                .getProduct(country, settings.getlng(), id, settings)
                .then((response) => {
                    if (response.status === 200) {
                        setAppState({ ...appState, show: true, product: response.data });

                        //Push GTM
                        searchApplication.pushGtmProductDetails(
                            response.data,
                            state?.searchData
                        );
                    } else {
                        consoleLogger(["ProductDetails:useEffect:getProduct", response]);
                    }
                })
                .catch(function (error) {
                    consoleLogger(["ProductDetails:useEffect:getProduct", error]);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Scroll to top on load
    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps

        const unlisten = history.listen((location, action) => {
            if (action === "POP" && !location.state) {
                location.state = state?.searchState;
            }
        });

        return () => unlisten(); // Cleanup the listener when the component unmounts
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (appState.show && appState.product) {
        const otherFragmentName = "Others";

        let productRegistrationLabel =
            "ProductRegistration" +
            searchApplication.removeWhiteSpace(
                appState.product.productDetail.countryName
            );

        if (appState.product.productDetail.keyInfo.category) {
            let productRegistrationWithCatLabel =
                productRegistrationLabel +
                searchApplication.removeWhiteSpace(
                    appState.product.productDetail.keyInfo.category.nameEN
                );
            if (i18n.exists(productRegistrationWithCatLabel)) {
                productRegistrationLabel = productRegistrationWithCatLabel;
            } else if (i18n.exists(productRegistrationLabel + otherFragmentName)) {
                productRegistrationLabel = productRegistrationLabel + otherFragmentName;
            }
        }

        return (
            <>
                <section>
                    <div
                        className={settings.is_mobile ? "container py-0" : "container py-0"}
                    >
                        <div
                            className={`p-d ${searchApplication.replaceSpecialCharacter(
                                appState.product.productDetail.countryName.toLowerCase()
                            )}`}
                        >
                            <div className="wrapper-grey row">
                                <div className="col-sm-12">
                                    {!settings.standalone ? (
                                        <RenderBackButton
                                            settings={settings}
                                            search={queryParam.search}
                                            country={country}
                                            id={id}
                                            searchState={state?.searchState}
                                        />
                                    ) : null}
                                    <RenderLastUpdated
                                        date={appState.product.productDetail.lastUpdatedDate}
                                    />
                                </div>
                                <div className="col-sm-12">
                                    {appState.product.productDetail.goldStar.toLowerCase() ===
                                        "true" ? (
                                        <div className="partner-product mb-3">
                                            <span className="sponsored p-1">
                                                <IconStar />
                                                <span>
                                                    <Trans>PartnerProduct</Trans>
                                                </span>
                                            </span>
                                        </div>
                                    ) : (
                                        <div className="mt-3"></div>
                                    )}
                                    <div className="product-image-name-container d-flex flex-row">
                                        <div className="product-image p-4 mb-4">
                                            <div
                                                style={{
                                                    backgroundImage: `url(${settings.category_icons_folder
                                                        }${searchApplication.createCategoryIconName(
                                                            appState.product.productDetail.keyInfo.category
                                                                .nameEN
                                                        )})`,
                                                }}
                                            ></div>
                                        </div>
                                        <div
                                            className={
                                                i18n.dir() === "ltr"
                                                    ? "ms-4 d-flex flex-column justify-content-center flex-1 product-name-container"
                                                    : "me-4 d-flex flex-column justify-content-center flex-1 product-name-container"
                                            }
                                        >
                                            <h1
                                                className="product-name"
                                                data-name-en={appState.product.productDetail.nameEN}
                                            >
                                                {searchApplication.getName(
                                                    appState.product.productDetail
                                                )}
                                            </h1>
                                            <div className="product-name-sub2">
                                                <CategoryLink
                                                    name={
                                                        appState.product.productDetail.keyInfo.category.name
                                                    }
                                                    nameEN={
                                                        appState.product.productDetail.keyInfo.category
                                                            .nameEN
                                                    }
                                                />
                                            </div>
                                            <RenderActiveIngredientsProductDetailHeader
                                                items={
                                                    appState.product.productDetail.keyInfo
                                                        .activeIngredient
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="line-break mt-3"></div>
                                </div>
                                <div className="col-sm-12 pt-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <RenderProductInfo
                                                        linkInfo={appState.product.productDetail.linkInfo}
                                                        haveStandard={
                                                            appState.product.productDetail.green ||
                                                            appState.product.productDetail.amber
                                                        }
                                                        green={appState.product.productDetail.green}
                                                        amber={appState.product.productDetail.amber}
                                                        productRegistrationLabel={t(
                                                            productRegistrationLabel
                                                        )}
                                                        addAnalytics={addAnalytics}
                                                    />
                                                </div>
                                                <div className="col-sm-12 details">
                                                    <div className="row">
                                                        <RenderBasicInfo
                                                            productDetail={appState.product.productDetail}
                                                        />
                                                        <RenderDistributorDetails
                                                            list={appState.product.productDetail.distributors}
                                                            haveStandard={
                                                                appState.product.productDetail.green ||
                                                                appState.product.productDetail.amber
                                                            }
                                                            addAnalytics={addAnalytics}
                                                        />

                                                        <RenderEnhancedInformation
                                                            list={searchApplication.getEnchancedInformation(
                                                                appState.product.productDetail
                                                                    .enhancedProductInfo
                                                            )}
                                                        ></RenderEnhancedInformation>
                                                        <div className="line-break mt-3"></div>
                                                    </div>
                                                </div>
                                                <PermittedUses
                                                    t={t}
                                                    country={country}
                                                    lng={settings.getlng()}
                                                    id={id}
                                                    crop={cropId ?? ""}
                                                    pest={pestId ?? ""}
                                                />
                                                <OtherPermittedUses
                                                    t={t}
                                                    country={country}
                                                    id={id}
                                                    lng={settings.getlng()}
                                                />
                                                <RenderCropPestSpec
                                                    cropSpec={
                                                        appState.product.productDetail.additionalCropSpec
                                                    }
                                                    pestSpec={
                                                        appState.product.productDetail.additionalPestSpec
                                                    }
                                                />
                                                <CategorySection
                                                    nameEN={
                                                        appState.product.productDetail.keyInfo.category
                                                            .nameEN
                                                    }
                                                />
                                                <CropSprayerApp
                                                    nameEN={
                                                        appState.product.productDetail.keyInfo.category
                                                            .nameEN
                                                    }
                                                />
                                                <div className="col-sm-12">
                                                    <h3 className="fw-bold py-3">
                                                        <Trans>Disclaimer</Trans>
                                                    </h3>
                                                    <p>
                                                        <Trans>CABIDisclaimer</Trans>
                                                    </p>
                                                </div>
                                                <div className="col-sm-12">
                                                    <RenderBackButton
                                                        settings={settings}
                                                        search={queryParam.search}
                                                        country={country}
                                                        id={id}
                                                        searchState={state?.searchState}
                                                    />
                                                </div>
                                                {!settings.standalone &&
                                                    t("Signuptext") !== "Signuptext" &&
                                                    t("Signup") !== "Signup" && (
                                                        <div className="col-sm-12">
                                                            <h3 className="fw-bold py-3">
                                                                <Trans>Signup</Trans>
                                                            </h3>
                                                            <div className="d-block signup">
                                                                <IconBell className="icon-size me-1 align-middle" />
                                                                <p
                                                                    className="d-inline"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: t("Signuptext"),
                                                                    }}
                                                                ></p>
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    } else if (appState.show && !appState.product) {
        return <RenderNotFound />;
    } else {
        return <LoadingComponent />;
    }
}

/**
 * Component to render product not found message
 * @returns
 */
function RenderNotFound() {
    return (
        <div className="message not-found">
            <h2 className="text-center py-5">Product not found!!!</h2>
        </div>
    );
}

/**
 * Component to render link info
 * @param {*} param0
 * @returns
 */
function RenderProductInfo({
    linkInfo,
    haveStandard,
    green,
    amber,
    productRegistrationLabel,
    addAnalytics,
}) {
    const productLinkInfo = searchApplication.getProductInfoLinks(linkInfo);

    return (
        <div className="detail-card product-info">
            <RenderProductStandard green={green} amber={amber} />
            <div className="std d-flex py-3 fc-g align-items-center">
                <IconShieldCheck className="sh-icon" />
                <span
                    className={
                        i18n.dir() === "rtl"
                            ? "reg me-2 product-std-fonts"
                            : "reg ms-2 product-std-fonts"
                    }
                    dangerouslySetInnerHTML={{
                        __html: productRegistrationLabel,
                    }}
                ></span>
            </div>

            <div className="my-3 d-flex justify-content-center flex-wrap-wrap product-detail-button-container">
                <div className="d-flex align-items-center product-detail-button">
                    {productLinkInfo.productFactsheet ? (
                        <a
                            href={productLinkInfo.productFactsheet}
                            onClick={function () {
                                addAnalytics("product_factsheet");
                            }}
                            target="_blank"
                            rel="noreferrer"
                            className="p-info-btn me-3 d-flex mb-3 fw-bold p-2 align-items-center text-decoration-none"
                        >
                            <IconDocument
                                className={i18n.dir() === "rtl" ? "ms-2" : "me-2"}
                            />
                            <div>
                                <Trans>ProductFactsheet</Trans>
                            </div>
                        </a>
                    ) : null}
                </div>
                <div className="d-flex align-items-center product-detail-button">
                    {productLinkInfo.productLabel ? (
                        <a
                            href={productLinkInfo.productLabel}
                            onClick={function () {
                                addAnalytics("product_label");
                            }}
                            target="_blank"
                            rel="noreferrer"
                            className="p-info-btn me-3 d-flex mb-3 fw-bold p-2 align-items-center text-decoration-none"
                        >
                            <IconLabel className={i18n.dir() === "rtl" ? "ms-2" : "me-2"} />
                            <div>
                                <Trans>ProductLabel</Trans>
                            </div>
                        </a>
                    ) : null}
                </div>
                <div className="d-flex product-detail-button">
                    {productLinkInfo.manufacturerSafetyDatasheet ? (
                        <a
                            href={productLinkInfo.manufacturerSafetyDatasheet}
                            onClick={function () { addAnalytics('manufacturer_safety_datasheet') }}
                            target="_blank"
                            rel="noreferrer"
                            className="p-info-btn me-3 d-flex mb-3 fw-bold p-2 align-items-center text-decoration-none"
                        >
                            <IconShield className={i18n.dir() === "rtl" ? "ms-2" : "me-2"} />
                            <div>
                                <Trans>ManufacturerSafetyDatasheet</Trans>
                            </div>
                        </a>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

/**
 * Component to render distributors
 * @param {*} param0
 * @returns
 */
function RenderDistributorDetails({ list, haveStandard, addAnalytics }) {
    if (Array.isArray(list)) {
        return (
            <div className="col-md-4 mb-3">
                {list.map((item, index) => (
                    <div className="grey-bg" key={index}>
                        <div className="detail-card distributor-card" key={item.id}>
                            <h3 className="top-header title text-center">
                                <Trans>DistributorDetails</Trans>
                            </h3>

                            <div className="my-2 container px-4">{item.companyName}</div>
                            {!item.contactDetails || !item.addressDetails ? null : (
                                <>
                                    <address data-id={item.id}>
                                        {index !== 0 ? <span /> : null}
                                        <div className="tel-email pt-2 container px-4">
                                            <ul className="p-0">
                                                {!item.addressDetails ? null : (
                                                    <li className="d-flex mb-3">
                                                        <IconLocation />
                                                        {item.addressDetails ? (
                                                            <>
                                                                {item.addressDetails.addressLine1
                                                                    ? item.addressDetails.addressLine1
                                                                    : null}
                                                                {item.addressDetails.addressLine2
                                                                    ? item.addressDetails.addressLine2
                                                                    : null}
                                                                <>
                                                                    {item.addressDetails.city
                                                                        ? " " + item.addressDetails.city
                                                                        : null}
                                                                    {item.addressDetails.town
                                                                        ? " " + item.addressDetails.town
                                                                        : null}
                                                                    {item.addressDetails.postCode
                                                                        ? " " + item.addressDetails.postCode
                                                                        : null}
                                                                    <span>{item.countryName}</span>
                                                                </>
                                                            </>
                                                        ) : (
                                                            <span>{item.countryName}</span>
                                                        )}
                                                    </li>
                                                )}
                                                {item.contactDetails.companyPhone ? (
                                                    <li className="d-flex mb-3">
                                                        <IconPhone />
                                                        <a
                                                            onClick={function () {
                                                                addAnalytics("distributor_phone_number");
                                                            }}
                                                            href={"tel:" + item.contactDetails.companyPhone}
                                                        >
                                                            {item.contactDetails.companyPhone}
                                                        </a>
                                                    </li>
                                                ) : null}
                                                {item.contactDetails.companyEmail ? (
                                                    <li className="d-flex mb-3">
                                                        <IconEmail />
                                                        <a
                                                            onClick={function () {
                                                                addAnalytics("distributor_email");
                                                            }}
                                                            href={
                                                                "mailto:" + item.contactDetails.companyEmail
                                                            }
                                                        >
                                                            {item.contactDetails.companyEmail}
                                                        </a>
                                                    </li>
                                                ) : null}
                                            </ul>
                                        </div>

                                        {item.contactDetails &&
                                            item.contactDetails.companyWebsite ? (
                                            <p className="url me-4 ms-4">
                                                <a
                                                    href={item.contactDetails.companyWebsite}
                                                    target="_blank"
                                                    onClick={function () {
                                                        addAnalytics("distributor_website");
                                                    }}
                                                    rel="noopener noreferrer"
                                                    className="fs-5 fw-bold d-flex py-2 px-3 align-items-center"
                                                >
                                                    {i18n.dir() === "rtl" ? <IconArrowRight /> : null}
                                                    <span>
                                                        <Trans>Distributors</Trans>
                                                    </span>
                                                    {i18n.dir() === "ltr" ? <IconArrowRight /> : null}
                                                </a>
                                            </p>
                                        ) : null}
                                    </address>
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        );
    }
    return null;
}

function RenderBasicInfo({ productDetail }) {
    return (
        <div className="col-md-4 mb-3">
            <div className="grey-bg">
                <h3 className="top-header title text-center">
                    <Trans>BasicInformation</Trans>
                </h3>
                <RenderOtherKeyInformation
                    item={productDetail.keyInfo.registrationNumber}
                    label={"RegistrationNumber"}
                />
                <div data-id={productDetail.keyInfo.category.id}>
                    <div className="key-info-container">
                        <h3 className="fw-bold fs-4">
                            <Trans>Productcategory</Trans>
                        </h3>
                        <CategoryLink
                            name={productDetail.keyInfo.category.name}
                            nameEN={productDetail.keyInfo.category.nameEN}
                        />
                    </div>
                </div>
                <RenderOtherKeyInformation
                    item={productDetail.keyInfo.registrant}
                    label={"Registrant"}
                    displayBorder={true}
                />
                <RenderManufacturer item={productDetail.keyInfo.manufacturer} />
            </div>
        </div>
    );
}

/**
 * Component to render standard
 * @param {*} param0
 * @returns
 */
function RenderProductStandard({ green, amber }) {
    if (Array.isArray(green) || Array.isArray(amber)) {
        return (
            <>
                {green &&
                    green.map((s, i) => (
                        <div
                            className="std d-flex py-3 fc-g align-items-center gap-1"
                            key={s.standardBasicData.standardName}
                        >
                            <LazyLoad>
                                <img
                                    className="prodstd-image-size"
                                    src={s.standardBasicData.standardLogoURL}
                                    alt={s.standardBasicData.standardName}
                                />
                            </LazyLoad>
                            <span
                                className={
                                    i18n.dir() === "rtl"
                                        ? "me-2 product-std-fonts"
                                        : "ms-2 product-std-fonts"
                                }
                            >
                                <Trans>Green</Trans>
                            </span>
                        </div>
                    ))}
                {amber &&
                    amber.map((s) => (
                        <div
                            className="prodstd-image-container standard amber d-flex align-items-center gap-1"
                            key={s.standardBasicData.standardName}
                        >
                            <LazyLoad className="d-inline">
                                <img
                                    className="prodstd-image-size"
                                    src={s.standardBasicData.standardLogoURL}
                                    alt={s.standardBasicData.standardName}
                                />
                            </LazyLoad>
                            <span className="text-dark">
                                <Trans>{s.standardBasicData.productDetailAmberLogoLabel}</Trans>
                            </span>
                            <StandardTooltipIcon type="amber" />
                            <StandardTooltip type="amber" description="AmberDescription" />
                        </div>
                    ))}
            </>
        );
    }
    return null;
}

/**
 * Component to render registration no, category and registrant details
 * @param {*} item
 * @param {*} label
 * @returns
 */
function RenderOtherKeyInformation({ item, label, displayBorder }) {
    let displayName = searchApplication.getName(item);
    if (displayName) {
        return (
            <div data-id={item.id}>
                <div className="key-info-container">
                    <h3 className="fw-bold fs-4">
                        <Trans>{label}</Trans>
                    </h3>
                    <span>{displayName}</span>
                </div>
            </div>
        );
    }
    return null;
}

/**
 * Component to render crop and pest additional specifications
 * @param {*} param0
 * @returns
 */
function RenderCropPestSpec({ cropSpec, pestSpec }) {
    if (Array.isArray(cropSpec) || Array.isArray(pestSpec)) {
        return (
            <>
                <div className="col-sm-12 ">
                    <RenderCropPestSpecDetail
                        items={cropSpec}
                        label="AdditionalSpecificationsforCrop"
                    />
                    <RenderCropPestSpecDetail
                        items={pestSpec}
                        label="AdditionalSpecificationsforPest"
                    />
                </div>
                <div className="col-12">
                    <div className="line-break"></div>
                </div>
            </>
        );
    }

    return null;
}

/**
 * Component to render crop and pest additional specifications details
 * @param {*} param0
 * @returns
 */
function RenderCropPestSpecDetail({ items, label }) {
    if (Array.isArray(items)) {
        return (
            <div className={label.toLowerCase()}>
                <h3 className="py-3">
                    <Trans>{label}</Trans>
                </h3>
                <p>{items.map((k) => searchApplication.getName(k)).join(", ")}</p>
            </div>
        );
    }
    return null;
}

/**
 * Component to render enhanced information
 * @param {*} param0
 * @returns
 */
function RenderEnhancedInformation({ list }) {
    if (Array.isArray(list) && list.length > 0) {
        return (
            <div className="col-md-4 mb-3">
                <div className="grey-bg">
                    <h3 className="top-header title text-center">
                        <Trans>AdditionalInformation</Trans>
                    </h3>
                    {list.map((item) => (
                        <div
                            className="mb-3"
                            data-id={item.labelTrans.toLowerCase()}
                            key={item.labelTrans}
                        >
                            <div className="key-info-container">
                                <h3 className="fw-bold fs-4">
                                    <Trans>{item.labelTrans}</Trans>
                                </h3>
                                <span>{item.values.map((k) => k.displayName).join(", ")}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    return null;
}

/**
 * Component to render last update date
 * @param {*} param0
 * @returns
 */
function RenderLastUpdated({ date }) {
    if (date) {
        return (
            <ul className="last-updated">
                <li>
                    <Trans>Lastupdated</Trans>:
                </li>
                <li>{date}</li>
            </ul>
        );
    }

    return null;
}

/**
 * Component to render back button
 * @param {*} settings
 * @param {*} queryParam
 * @returns Render back button
 */
function RenderBackButton({ settings, search, country, searchState }) {
    return (
        <Link
            to={{
                pathname: settings.searchpage_url + "/",
                search: searchApplication.historyHelper.productBackUrl(
                    searchState,
                    country,
                    searchState?.showLng ?? false
                ),
                state: searchState,
            }}
            className="btn-tertiary text-decoration-none align-middle back-to-search-text"
        >
            {i18n.dir() === "ltr" ? (
                <IconArrowLeft className="me-1 align-middle" />
            ) : null}
            <Trans>BackToSearchResults</Trans>
            {i18n.dir() === "rtl" ? (
                <IconArrowLeft className="me-1 align-middle" />
            ) : null}
        </Link>
    );
}

/**
 * Component to render active ingredient in product details header
 * @param {Array} items
 * @returns render active ingredients list
 */
function RenderActiveIngredientsProductDetailHeader({ items }) {
    if (Array.isArray(items) && items.length > 0) {
        let activeIngredients = (items ?? []).map((i) => {
            return { ...i, displayName: searchApplication.getName(i) };
        });

        activeIngredients = activeIngredients.sort(
            searchApplication.sortOnAsc("displayName")
        );

        return (
            <ul className="active-in p-0">
                {activeIngredients.map((item, i) => (
                    <li data-id={item.id} key={i}>
                        <span className="product-name-sub2">
                            {searchApplication.getName(item)}
                        </span>
                    </li>
                ))}
            </ul>
        );
    } else {
        return null;
    }
}

/**
 * Component to render manufacturer in product details
 * @param {Array} items
 * @returns render manufacturer list
 */
function RenderManufacturer({ item }) {
    if (item) {
        return (
            <div className="manufacturer">
                <div className="key-info-container">
                    <h3 className="fw-bold fs-4">
                        <Trans>Manufacturer</Trans>
                    </h3>
                    <div>
                        <span className="border-label">
                            {searchApplication.getName(item)}
                        </span>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default withTranslation()(ProductDetails);
