import React, { useState, useEffect } from "react";
import { withTranslation, Trans } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import { SearchForm } from "../searchForm";
import { SearchResult } from "../searchResult";

import { SettingsContext } from "../settingsContext";
import { SearchProvider } from "../searchContext";
import { LoadingComponent } from "../loading";
import { ErrorUI } from "../errorUI";
import i18n from "../../i18n";

import { dataServices } from "../../services/dataServices";
import { searchApplication, consoleLogger } from "../../services/utilities";
import { IconArrowLeft } from "../icons";

import "../../scss/Search.scss";

/**
 * Component to render search UI
 * @param {*} props
 * @returns render search UI
 */
function Search(props) {
    const { t } = props;

    const settings = React.useContext(SettingsContext);

    //Get query param from Url
    const queryParam = searchApplication.historyHelper.queryParam(
        useLocation().search
    );

    const history = useHistory();

    //Result sort order list
    const sortOrderOptions = [
        { value: "ASC", label: t("Product(A-Z)") },
        { value: "DESC", label: t("Product(Z-A)") },
    ];

    //State for handling errors
    const [errorState, setErrorState] = useState(false);

    //Get language from the attribute
    const language = settings.getlng();

    //Create the search page state
    const [appState, setAppState] = useState({
        country: queryParam.country,
        countryName: null,

        language: language,
        occupations: [],

        countries: [],
        languages: [],

        crops: [],
        pests: [],
        activeIngredients: [],
        supportedStates: [],

        occupation: null,
        crop: null,
        pest: null,
        result: null,
        activeIngredient: null,
        supportedState: null,

        sortOrder: queryParam.sortOrder
            ? queryParam.sortOrder.toLowerCase() === "desc"
                ? sortOrderOptions[1]
                : sortOrderOptions[0]
            : null,

        disabledCrop: true,
        disabledPest: true,
        disabledOccupation: true,

        showError: false,
        filter: queryParam.filter,
    });

    /**
     * Method when a search is perfomed
     * @param {object} resultFromSearch
     * @param {object} newState
     */
    const searchPerformed = (
        resultFromSearch,
        newState = null,
        activeIngredientId = null,
        sortOrder = null,
        selectedStates = null
    ) => {
        var searchState = props?.location?.state;

        var sortedResult = null;

        //No need to sort if return back to search is performed
        if (searchState && sortOrder === null) {
            var productsCopy = [...resultFromSearch];
            productsCopy.forEach((item) => {
                item.displayName = searchApplication.getName(item.product);
            });

            sortedResult = productsCopy;

            //clear state
            let state = null;
            history.replace({ ...history.location, state });
        } else {
            //Sort the result asc/desc/random based on the value of sortOrder
            sortedResult = sortOrder
                ? searchApplication.sortProductByName(resultFromSearch, sortOrder.value)
                : searchApplication.sortProductByName(resultFromSearch);
        }

        //set supported-state
        var stateSelection = [];
        if (selectedStates) {
            selectedStates.split(",").forEach(function (state) {
                stateSelection.push({
                    value: state,
                    label: state,
                });
            });
        }

        // Get sorted unique active ingredients list from the result
        var activeIngredients = searchApplication.activeIngredients(
            sortedResult,
            stateSelection
        );

        // Get sorted supported states list from the result
        var supportedStates = searchApplication.supportedStates(
            sortedResult,
            activeIngredientId
        );

        //if newState is undefined assign the appState
        newState = newState ?? appState;

        //set active-ingredient
        var activeIngredient = null;
        if (activeIngredients.length > 0 && activeIngredientId) {
            var selected = activeIngredients.filter(
                (item) => item.value === activeIngredientId
            );

            if (selected.length === 1) {
                activeIngredient = {
                    label: selected[0].label,
                    label_en: selected[0].label_en,
                    value: selected[0].value,
                };
            }
        }

        //set supported-state
        var mySelectedState = [];
        if (supportedStates.length > 0 && selectedStates) {
            selectedStates.split(",").forEach(function (state) {
                selected = supportedStates.filter((item) => item.value === state);

                if (selected.length === 1) {
                    mySelectedState.push({
                        value: selected[0].value,
                        label: selected[0].label,
                    });
                }
            });
        }

        //Set the new state
        setAppState({
            ...newState,
            result: sortedResult,
            activeIngredients: activeIngredients,
            activeIngredient: activeIngredient,
            supportedStates: supportedStates,
            supportedState: mySelectedState,
            sortOrder: sortOrder,
            showError: false,
        });
    };

    const handleScrollPosition = () => {
        const scrollPosition = sessionStorage.getItem("scrollPosition");
        if (scrollPosition) {
            setTimeout(function () {
                window.scrollTo(0, parseInt(scrollPosition));
                sessionStorage.removeItem("scrollPosition");
            }, 100);
        }
    };

    useEffect(() => {
        var searchState = props?.location?.state;

        //Check wheather returns from product details page
        //If get state, then render the search page with that state
        if (searchState) {
            var newState = searchState;

            searchPerformed(
                newState.result,
                newState,
                newState.activeIngredient ? newState.activeIngredient.value : null,
                newState.sortOrder
                    ? newState.sortOrder && newState.sortOrder.value.toLowerCase() === "desc"
                        ? sortOrderOptions[1]
                        : sortOrderOptions[0]
                    : null,
                newState.supportedState
                    ? newState.supportedState
                        .map(function (item) {
                            return item.value;
                        })
                        .join(",")
                    : null
            );

            handleScrollPosition();

        } else if (queryParam.country) {
            dataServices
                .getInitialData(
                    queryParam.country,
                    language,
                    queryParam.crop,
                    queryParam.pest,
                    queryParam.filter,
                    settings
                )
                .then((response) => {
                    if (response.status === 200) {
                        const data = response.data;

                        if (data.countryName === "" || data.occupations.length === 0) {
                            setErrorState(true);
                            return;
                        } else {
                            setErrorState(false);
                        }

                        //create new state
                        var newState = searchApplication.transformInitialData(
                            appState,
                            language,
                            queryParam.occupation,
                            queryParam.crop,
                            queryParam.pest,
                            data,
                            t
                        );

                        searchPerformed(
                            newState.result,
                            newState,
                            queryParam.activeIngredient,
                            queryParam.sortOrder
                                ? queryParam.sortOrder.toLowerCase() === "desc"
                                    ? sortOrderOptions[1]
                                    : sortOrderOptions[0]
                                : null,
                            queryParam.supportedState
                        );

                        if (
                            settings.isGTMEnabled &&
                            newState.countryName &&
                            newState.language
                        ) {
                            //push gtm
                            searchApplication.pushGtm(
                                queryParam.country,
                                newState.countryName,
                                newState.language ? newState.language.label : null,
                                newState.language ? newState.language.value : null,
                                newState.occupation !== null ? newState.occupation.label : "",
                                newState.occupation !== null
                                    ? newState.occupation.label_en
                                    : "",
                                newState.crop !== null ? newState.crop.value : "",
                                newState.crop !== null ? newState.crop.label : "",
                                newState.crop !== null ? newState.crop.label_en : "",
                                newState.pest !== null ? newState.pest.value : "",
                                newState.pest !== null ? newState.pest.label : "",
                                newState.pest !== null ? newState.pest.label_en : "",
                                data.products ? data.products.length : 0
                            );
                        }
                    } else {
                        consoleLogger(["Search:useEffect:getInitialData", response]);
                        setErrorState(true);
                    }
                })
                .catch(function (error) {
                    consoleLogger(["Search:useEffect:getInitialData", error]);
                    setErrorState(true);
                });
        } else {
            newState = { ...appState, country: "", countryName: "Select" };
            searchPerformed(
                null,
                newState,
                queryParam.activeIngredient,
                queryParam.sortOrder
                    ? queryParam.sortOrder.toLowerCase() === "desc"
                        ? sortOrderOptions[1]
                        : sortOrderOptions[0]
                    : null,
                queryParam.supportedState
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Scroll to top on load
    useEffect(() => {
        //window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (appState.countryName && !errorState) {
        return (
            <SearchProvider value={{ appState, setAppState }}>
                <div
                    className={`s-p ${searchApplication.replaceSpecialCharacter(
                        appState.countryName.toLowerCase()
                    )}`}
                >
                    {!settings.standalone ? <section>
                        <div className="container pt-3 pb-3">
                            <div className="row">
                                <div className="col-12">
                                    <a
                                        href={
                                            searchApplication.getDocLng() === settings.default_lang || settings.standalone
                                                ? "/"
                                                : `/${searchApplication.getDocLng()}`
                                        }
                                        className="btn-tertiary text-decoration-none align-middle"
                                    >
                                        {i18n.dir() === 'ltr' ? <IconArrowLeft className="me-1 align-middle" /> : null}
                                        <Trans>BackToHome</Trans>
                                        {i18n.dir() === 'rtl' ? <IconArrowLeft className="me-1 align-middle" /> : null}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section> : null}
                    <SearchForm
                        sortOrderOptions={sortOrderOptions}
                        searchPerformed={searchPerformed}
                    />
                    <SearchResult sortOrderOptions={sortOrderOptions}></SearchResult>
                </div>
            </SearchProvider>
        );
    } else {
        return errorState ? <ErrorUI /> : <LoadingComponent />;
    }
}

export default withTranslation()(Search);
