import React, { useEffect } from "react";
import { withTranslation, Trans } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";

import { searchApplication } from "../../services/utilities";
import { SearchContext } from "../searchContext";
import { SettingsContext } from "../settingsContext";
import { NoOptionsMessage } from "../noOptionsMessage";

import {
    IconBatch,
    IconStar,
    IconBell,
    IconExclamation,
    IconExrrorExclamatory
} from "../icons";

/**
 * Component to render search result
 * @param {*} props
 * @returns render search result
 */
function SearchResult(props) {
    const { t, i18n, sortOrderOptions } = props;

    const searchContext = React.useContext(SearchContext);

    const appState = searchContext.appState;

    const setAppState = searchContext.setAppState;

    //Get the settings from context
    const settings = React.useContext(SettingsContext);

    const history = useHistory();

    //Filter result based on selected active ingredient
    var result = searchContext.appState.activeIngredient
        ? searchApplication.filteredByActiveIngredient(
            searchContext.appState.result,
            searchContext.appState.activeIngredient.value
        )
        : searchContext.appState.result;

    // filter result based on selected state
    result =
        searchContext.appState.supportedState &&
            searchContext.appState.supportedState.length
            ? searchApplication.filteredByState(
                result,
                searchContext.appState.supportedState
            )
            : result;

    /**
     * Event listner for sort drop down change
     * @param {*} order
     */
    const sortOrderChange = (order) => {
        var sortedResult = order
            ? searchApplication.sortProductByName(appState.result, order.value)
            : searchApplication.sortProductByName(appState.result);

        var newState = { ...appState, sortOrder: order, result: sortedResult };

        setAppState(newState);

        updateHistory(newState);
    };

    /**
     * Event listner for active ingredient filter change
     * @param {*} selected active ingredient
     */
    const activeFilterChange = (selected) => {
        var supportedStates = selected
            ? searchApplication.supportedStates(appState.result, selected.value)
            : searchApplication.supportedStates(appState.result);

        var activeIngredients = appState.activeIngredients;

        if (appState.supportedState) {
            activeIngredients = searchApplication.activeIngredients(
                appState.result,
                appState.supportedState
            );
        }

        var newState = {
            ...appState,
            activeIngredients: activeIngredients,
            activeIngredient: selected,
            supportedStates: supportedStates,
        };

        setAppState(newState);

        updateHistory(newState);
    };

    /**
     * Event listner for supported state filter change
     * @param {*} selected active ingredient
     */
    const stateFilterChange = (selected) => {
        var activeIngredients =
            selected && selected.length
                ? searchApplication.activeIngredients(appState.result, selected)
                : searchApplication.activeIngredients(appState.result);

        var supportedStates = appState.supportedStates;

        if (appState.activeIngredient) {
            supportedStates = searchApplication.supportedStates(
                appState.result,
                appState.activeIngredient.value
            );
        }

        var newState = {
            ...appState,
            supportedStates: supportedStates,
            supportedState: selected,
            activeIngredients: activeIngredients,
        };

        setAppState(newState);

        updateHistory(newState);
    };

    /**
     * Update URL when filtered by active ingredients or sorted
     * @param {object} newState
     */
    const updateHistory = (newState) => {
        var crop = newState.crop !== null ? newState.crop.value ?? "" : "";
        var pest = newState.pest != null ? newState.pest.value ?? "" : "";
        var filter = newState.filter != null ? newState.filter ?? "" : "";
        var activeIngredient =
            newState.activeIngredient != null
                ? newState.activeIngredient.value ?? ""
                : "";

        var supportedState =
            newState.supportedState && newState.supportedState.length
                ? newState.supportedState ?? ""
                : "";

        var sortOrder = newState.sortOrder ? newState.sortOrder.value : null;

        searchApplication.historyHelper.updateHistory(history, {
            country: newState.country,
            lng: newState.language ? newState.language.value : null,
            crop: crop,
            pest: pest,
            filter: filter,
            activeIngredient: activeIngredient,
            supportedState: supportedState,
            sortOrder: sortOrder,
            standalone: settings.standalone
        });
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        // scroll to the result once its displayed on first load of page one time
        // added 10 milliseconds delay as on first load of page - scrolling is not happening exactly at result count element
        // could be because delay in loading filter element.
        setTimeout(() => {
            var offset = 0;
            var customcontainers =
                document.getElementsByClassName("custom-container");
            if (customcontainers.length) {
                offset =
                    customcontainers.length === 2
                        ? customcontainers[1].getBoundingClientRect().height
                        : customcontainers[0].getBoundingClientRect().height;
            }

            if (!offset) {
                var mobileHeader = document.getElementsByClassName(
                    "mobile-header-inner"
                );
                if (mobileHeader.length) {
                    offset = mobileHeader[0]
                        .querySelector(".container")
                        .getBoundingClientRect().height;
                }
            }

            const section = document.querySelector(".result-count");
            if (section) {
                var brect = section.getBoundingClientRect();
                var scrollOption = {
                    top: brect.top - offset + document.documentElement.scrollTop - 16,
                    left: 0,
                    behavior: "smooth",
                };
                window.scrollTo(scrollOption);
            }
        }, 10);
    }, []);

    //Passing search information to product details
    let searchData = {
        occupation:
            appState.occupation !== null ? appState.occupation.label : "No Data",
        occupation_en:
            appState.occupation !== null ? appState.occupation.label_en : "No Data",
        crop_id: appState.crop !== null ? appState.crop.value : "No Data",
        crop: appState.crop !== null ? appState.crop.label : "No Data",
        crop_en: appState.crop !== null ? appState.crop.label_en : "No Data",
        pest_id: appState.pest !== null ? appState.pest.value : "No Data",
        pest: appState.pest !== null ? appState.pest.label : "No Data",
        pest_en: appState.pest !== null ? appState.pest.label_en : "No Data",
        result_count: appState.result?.length ?? 0,
    };

    //Whether to show missing product notice for the country
    let showProductMissingNotice = false;
    if (settings.country_code_missing_product) {
        let countryCodes = settings.country_code_missing_product
            .toLowerCase()
            .split(",")
            .filter((x) => {
                return x.toLowerCase() === appState.country.toLowerCase();
            });

        showProductMissingNotice = countryCodes.length > 0;
    }

    const countrySelected = (code) => {
        var selected = appState.countries.filter((item) => item.value === code);

        if (selected && selected.length > 0) {
            return selected[0].label;
        }

        return null;
    };

    const queryParam = searchApplication.historyHelper.queryParam(
        useLocation().search
    );

    const cropSelected = () => {
        if (queryParam.crop) {
            var selected = appState.crops.filter(
                (item) => item.value === queryParam.crop
            );

            if (selected && selected.length > 0) {
                return selected[0].label;
            } else if (appState.crop) {
                return appState.crop.label;
            }
        }
        return null;
    };

    const pestSelected = () => {
        var selected = appState.pests.filter(
            (item) => item.value === queryParam.pest
        );

        if (selected && selected.length > 0) {
            return selected[0].label;
        } else if (appState.pest) {
            return appState.pest.label;
        }

        return null;
    };

    //When search not performed or search with 0 result
    if (result === null || result.length === 0) {
        return (
            <section data-testid="result-wrapper">
                <div className="container"></div>
            </section>
        );
    } else {
        return (
            <section data-testid="result-wrapper">
                <div className="container py-4 s-r">
                    <div className="row">
                        <div className="col-sm-12 information-container">
                            <IconBatch className="me-1 align-middle" />
                            <div className="information">
                                <h4 className="fw-normal lh-sm"
                                    dangerouslySetInnerHTML={{
                                        __html: t(
                                            "ProductRegistration" +
                                            searchApplication.removeWhiteSpace(appState.countryName)
                                        ),
                                    }}
                                ></h4>
                            </div>
                        </div>
                        {!settings.standalone && t("Signuptext") !== "Signuptext" && <div className="col-sm-12 d-flex my-3">
                            <IconBell className="icon-size me-1 align-middle" />
                            <div className="information">
                                <h4 className="fw-normal lh-sm m-0"
                                    dangerouslySetInnerHTML={{
                                        __html: t("Signuptext"),
                                    }}
                                ></h4>
                            </div>
                        </div>}
                        <div className="col-sm-12">
                            <h4 className="mt-3 mb-4">
                                <span>{result.length}</span>{" "}
                                {result.length > 1 ? (
                                    <Trans>Results</Trans>
                                ) : (
                                    <Trans>Result</Trans>
                                )}
                                {
                                    <span className="badge rounded-pill bg-cabi text-dark fs-6 me-1 mb-2 ms-1">
                                        {countrySelected(appState.country)}
                                    </span>
                                }
                                {cropSelected() ? (
                                    <span className="badge rounded-pill fs-6 bg-cabi text-dark me-1">
                                        {cropSelected()}
                                    </span>
                                ) : null}
                                {pestSelected() ? (
                                    <span className="badge rounded-pill fs-6 bg-cabi text-dark ">
                                        {pestSelected()}
                                    </span>
                                ) : null}
                            </h4>
                        </div>
                    </div>
                    <div className="row s-r">
                        {showProductMissingNotice ? (
                            <div className="col-sm-12 pb-4 d-flex align-items-start">
                                <div className="me-1">
                                    <IconExclamation width="20px" />
                                </div>
                                <div
                                    className="fs-5 fw-bold pt-1"
                                    dangerouslySetInnerHTML={{ __html: t("ProductMissingLabel") }}
                                ></div>
                            </div>
                        ) : null}
                        <div className="col-sm-12 s-f">
                            <form>
                                <div className="row">
                                    <div className="col-sm-12 col-md-7">
                                        <div className="filter">
                                            <div className="control-container">
                                                <label className="d-block fw-bold pb-1 text-start"><Trans>Activeingredient</Trans></label>
                                                <Select
                                                    className={
                                                        settings.is_mobile
                                                            ? "basic-single w-100 mb-3"
                                                            : "basic-single"
                                                    }
                                                    classNamePrefix="select-react"
                                                    isDisabled={false}
                                                    isLoading={false}
                                                    isClearable={true}
                                                    isRtl={searchApplication.isRTL(i18n)}
                                                    isSearchable={true}
                                                    name="active-filter"
                                                    placeholder={t("Activeingredient")}
                                                    options={searchContext.appState.activeIngredients}
                                                    value={searchContext.appState.activeIngredient}
                                                    onChange={activeFilterChange}
                                                    components={{ NoOptionsMessage }}
                                                />
                                            </div>
                                            {searchContext.appState.supportedStates &&
                                                searchContext.appState.supportedStates.length ? (
                                                <div className="control-container">
                                                    <label className={
                                                        settings.is_mobile
                                                            ? "d-block fw-bold pb-1 text-start"
                                                            : "d-block fw-bold pb-1 text-start ms-2"
                                                    }><Trans>State</Trans></label>
                                                    <Select
                                                        isMulti
                                                        className={
                                                            settings.is_mobile
                                                                ? "basic-multi w-100 mb-3"
                                                                : "basic-multi ms-2"
                                                        }
                                                        classNamePrefix="select-react"
                                                        isDisabled={false}
                                                        isLoading={false}
                                                        isClearable={true}
                                                        isRtl={searchApplication.isRTL(i18n)}
                                                        isSearchable={true}
                                                        name="active-filter"
                                                        placeholder={t("State")}
                                                        options={searchContext.appState.supportedStates}
                                                        value={searchContext.appState.supportedState}
                                                        onChange={stateFilterChange}
                                                        components={{ NoOptionsMessage }}
                                                    />
                                                </div>
                                            ) : (
                                                ""
                                            )}

                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-5">
                                        <div className="sortby float-end">
                                            <label className="d-block fw-bold pb-1 text-start"><Trans>SortBy</Trans></label>
                                            <Select
                                                className={
                                                    settings.is_mobile
                                                        ? "basic-single w-100"
                                                        : "basic-single"
                                                }
                                                classNamePrefix="select-react"
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={false}
                                                isRtl={searchApplication.isRTL(i18n)}
                                                isSearchable={false}
                                                name="sort-filter"
                                                placeholder={t("SortBy")}
                                                options={sortOrderOptions}
                                                value={appState.sortOrder}
                                                onChange={sortOrderChange}
                                                components={{ NoOptionsMessage }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-sm-12">
                            <div className="row is-flex">
                                {result.map((item) =>
                                    item.product.goldStar ? (
                                        <RenderProductInfo
                                            key={item.product.id}
                                            item={item}
                                            appState={appState}
                                            settings={settings}
                                            searchData={searchData}
                                        />
                                    ) : null
                                )}
                                {result.map((item) =>
                                    !item.product.goldStar &&
                                        (item.product.isSponsor ?? "no").toLowerCase() === "yes" ? (
                                        <RenderProductInfo
                                            key={item.product.id}
                                            item={item}
                                            appState={appState}
                                            settings={settings}
                                            searchData={searchData}
                                        />
                                    ) : null
                                )}
                                {result.map((item) =>
                                    !item.product.goldStar &&
                                        (item.product.isSponsor ?? "no").toLowerCase() === "no" ? (
                                        <RenderProductInfo
                                            key={item.product.id}
                                            item={item}
                                            appState={appState}
                                            settings={settings}
                                            searchData={searchData}
                                        />
                                    ) : null
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

/**
 * Component to render product information
 * @param {*} param0
 * @returns
 */
function RenderProductInfo({ item, appState, settings, searchData }) {
    //Create a list with new property display name
    let activeIngredients = (item.product.activeIngredient ?? []).map((i) => {
        return { ...i, displayName: searchApplication.getName(i) };
    });

    //Sort the list
    activeIngredients = activeIngredients.sort(
        searchApplication.sortOnAsc("displayName")
    );

    let calculatePadding = (item) => {
        let count = 0;
        if (item.green) count += item.green.length;
        if (item.amber) count += item.amber.length;

        return 100 * count + "px";
    };

    const createProductURL = function (item) {
        const name = item.product.nameEN;
        const slug = searchApplication.createURLComponentSlug(name);
        const qlng = (settings.standalone || appState.showLng) && appState.language
            ? `&lng=${appState.language.value}`
            : '';

        return `${settings.productdetails_url}/${slug}-${item.product.id}/?country=${appState.country}${qlng}`;
    }

    const handleClick = e => {
        sessionStorage.setItem("scrollPosition", window.pageYOffset);
    };

    return (
        <div className="col-xs-1 col-md-6 col-lg-4 d-flex">
            <Link
                to={{
                    pathname: createProductURL(item),
                    state: { searchData: searchData, searchState: appState },
                }}
                style={{ paddingBottom: calculatePadding(item) }}
                className={
                    item.product.authorizeStatus
                        ? `mt-4 result-card rounded-3 text-decoration-none w-100 position-relative bg-white ${item.product.authorizeStatus.toLowerCase()}`
                        : "result-card rounded-3 text-decoration-none w-100 position-relative bg-white"
                }
                data-id={item.product.id}
                onClick={handleClick}
            >
                {item.product.goldStar ? (
                    <span className="sponsored d-flex d-block p-1 justify-content-center align-items-center">
                        <IconStar className="align-middle me-1" />
                        <span className="fw-bold align-middle">
                            <Trans>PartnerProduct</Trans>
                        </span>
                    </span>
                ) : null}
                <div
                    className={
                        item.product.goldStar
                            ? "category text-center pt-3 pb-3"
                            : "category text-center round pt-3 pb-3"
                    }
                >
                    {item.product.category ?
                        <img
                            alt={item.product.category.name}
                            src={`${settings.category_icons_folder
                                }${searchApplication.createCategoryIconName(
                                    item.product.category.nameEN
                                )}`}
                        />
                        : null}
                </div>
                <div className="ps-4 pe-4 pt-3 pb-3">
                    <h4 className="product-name">{item.displayName}</h4>
                    <label className="category-label fw-bold mb-0">
                        {searchApplication.getName(item.product.category)}
                    </label>
                    <ul className="active-ingredient p-0 pt-1 pb-2 mb-2 ">
                        <label>
                            <Trans>Activeingredient</Trans>
                        </label>
                        {activeIngredients.map((aingredient, i) => (
                            <li className="ms-3" data-id={aingredient.id} key={i}>
                                {aingredient.displayName}
                            </li>
                        ))}
                    </ul>
                    {item.company && (
                        <ul className="company p-0">
                            <RenderRegistrant item={item.company.registrant} />
                            <RenderManufacturer item={item.company.manufacturer} />
                            <RenderDistributors items={item.company.distributors} />
                        </ul>
                    )}
                </div>
                {(item.green || item.amber) && (
                    <div className="standards ps-4 pe-4 pt-3 position-absolute w-100">
                        {item.green &&
                            item.green.map((s, i) => (
                                <div className="green d-flex p-0 mb-2 align-items-center gap-1" key={s.standardBasicData.standardId}>
                                    <img
                                        src={s.standardBasicData.standardLogoURL}
                                        alt={s.standardBasicData.standardName}
                                    />
                                    <span className="fs-5">
                                        <Trans>Green</Trans>
                                    </span>
                                </div>
                            ))}
                        {item.amber &&
                            item.amber.map((s) => (
                                <div key={s.standardBasicData.standardId} className="ambar d-flex p-0 mb-2 align-items-center gap-1">
                                    <img
                                        src={s.standardBasicData.standardLogoURL}
                                        alt={s.standardBasicData.standardName}
                                    />
                                    <IconExrrorExclamatory className="position-absolute" fill={"#cd4400"} />
                                    <span className="fs-5 text-dark">
                                        <Trans>
                                            {s.standardBasicData.productDetailAmberLogoLabel}
                                        </Trans>
                                    </span>
                                </div>
                            ))}
                    </div>
                )}
                {!item.green && !item.amber && <div className="pb-3"></div>}
            </Link>
        </div>
    );
}

/**
 * Component to render distributors
 * @param {Array} param0
 * @returns
 */
function RenderDistributors({ items }) {
    return Array.isArray(items) && items.length > 0 ? (
        <li className="distributors pb-2">
            <ul className="p-0">
                <label className="fs-5">
                    <Trans>Distributors</Trans> :
                </label>
                {items.map((item) => (
                    <li className="d-inline" data-id={item.id} key={item.id}>
                        <span className="fs-5"> {searchApplication.getName(item)}</span>
                    </li>
                ))}
            </ul>
        </li>
    ) : null;
}

/**
 * Component to render registrant
 * @param {*} param0
 * @returns
 */
function RenderRegistrant({ item }) {
    return item ? (
        <li className="registrant pb-2" data-id={item.id}>
            <span className="fs-5">
                <Trans>Registrant</Trans>: {searchApplication.getName(item)}
            </span>
        </li>
    ) : null;
}

/**
 * Component to render manufaturer
 * @param {*} param0
 * @returns
 */
function RenderManufacturer({ item }) {
    return item ? (
        <li className="manufacturer pt-2 pb-2" data-id={item.id}>
            <span className="fs-5">
                <Trans>Manufacturer</Trans>: {searchApplication.getName(item)}
            </span>
        </li>
    ) : null;
}

export default withTranslation()(SearchResult);
