import axios from "axios";
import { stringInterpolator } from "./utilities";

/**
 * Dataservice to get data from API
 */
export const dataServices = {
    /**
     * Get initial data(languages, occupation, crop, pest and search result) from API
     *
     * @param {string} [country] Country code
     * @param {string} [language] Language code
     * @param {string} [crop] Crop ID
     * @param {string} [pest]  Pest ID
     * @param {string} [filter] filter(crop,pest) on which crop and pest list to filter, if value is 'crop', then pest list filter by crop and vice-versa
     * @param {object} [settings] Settings value
     */
    getInitialData: async (country, language, crop, pest, filter, settings) => {
        const apiUrl = stringInterpolator(
            `${settings.api_url}/${settings.initialdata_endpoint}`,
            {
                country: country,
                language: language,
                crop: crop ?? "",
                pest: pest ?? "",
                filter: filter ?? "",
                includeProducts: crop !== null || pest !== null ? "true" : "false",
            }
        );

        return await axios.get(apiUrl);
    },

    getCrop: async (country
        , language
        , pest
        , settings
        , search = ""
        , productId = ""
        , cancelToken = null
        , notShowProgress = false) => {
        const apiUrl = stringInterpolator(
            `${settings.api_url}/${settings.crops_endpoint}`,
            { country: country, language: language, pest: pest, search: search, productId: productId }
        );
        return await axios.get(apiUrl, { notShowProgress: notShowProgress, cancelToken: cancelToken });
    },

    getPest: async (country
        , language
        , crop
        , settings
        , search = ""
        , productId = ""
        , cancelToken = null
        , notShowProgress = false) => {
        const apiUrl = stringInterpolator(
            `${settings.api_url}/${settings.pests_endpoint}`,
            { country: country, language: language, crop: crop, search: search, productId: productId }
        );

        return await axios.get(apiUrl, { notShowProgress: notShowProgress, cancelToken: cancelToken });
    },

    getCropsPests: async (country, language, productId, crop, pest, page, sort, settings, notShowProgress = true) => {
        const apiUrl = stringInterpolator(
            `${settings.api_url}/${settings.crops_pests_endpoint}`,
            { country: country, language: language, productId: productId, crop: crop, pest: pest, size: settings.page_size, page: page, sort: sort }
        );

        return await axios.get(apiUrl, { notShowProgress: notShowProgress });
    },

    getLanguages: async (country, settings) => {
        const apiUrl = stringInterpolator(
            `${settings.api_url}/${settings.language_endpoint}`,
            { country: country }
        );

        return await axios.get(apiUrl);
    },

    getProducts: async (country, language, crop, pest, settings) => {
        const apiUrl = stringInterpolator(
            `${settings.api_url}/${settings.search_endpoint}`,
            {
                country: country,
                language: language,
                crop: crop,
                pest: pest,
            }
        );

        return await axios.get(apiUrl);
    },

    getProduct: async (country, language, id, settings) => {
        const apiUrl = stringInterpolator(
            `${settings.api_url}/${settings.productdetails_endpoint}`,
            {
                country: country,
                language: language,
                productId: id,
            }
        );

        return await axios.get(apiUrl);
    },
};
