import React, { useState, useEffect } from "react";
import TreeView from "react-treeview";
import Select from "react-select";
import { withTranslation, Trans } from "react-i18next";

import { SettingsContext } from "../settingsContext";
import { dataServices } from "../../services/dataServices";
import { searchApplication, consoleLogger } from "../../services/utilities";
import { NoOptionsMessage } from "../noOptionsMessage";
import { treeNodeDetails } from "./TreeNodeDetails.js";
import { IconCrop, IconPest } from "../icons";

/**
 * Component to render crop/pest tree view for other permitted use
 * @param {Array} List of crop/pests
 * @returns Render crop/pest tree
 */
function OtherPermittedUses(props) {

    const { t, i18n, country, lng, id } = props;

    const settings = React.useContext(SettingsContext);

    const [appState, setAppState] = useState({
        data: [],
        list: [],
        searchBy: null,
        value: null,
    });

    const [appStateCropPest, setAppStateCropPest] = useState({
        crops: [],
        pests: []
    });

    const [showMoreState, setShowMoreState] = useState(true);

    const maxChildToShow = 9;

    const onValueChange = (selectedOption) => {
        const newState = {
            ...appState,
            value: selectedOption,
        };
        setAppState(newState);

        setShowMoreState(true);
    };

    const getData = () => {
        if (appState.searchBy === 'crop') {
            if (appStateCropPest.crops.length > 0) {
                setAppState({
                    ...appState,
                    list: appStateCropPest.crops
                });
            }
            else {
                dataServices
                    .getCrop(
                        country,
                        lng,
                        appState.value === null ? null : appState.value.value,
                        settings,
                        '',
                        id,
                        null,
                        false
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            const data = response.data;
                            const crops = data.map(function (item) {
                                return {
                                    value: item.id,
                                    label: item.name,
                                    label_en: item.nameEN,
                                };
                            });

                            setAppStateCropPest({
                                ...appStateCropPest,
                                crops: crops
                            });

                            setAppState({
                                ...appState,
                                list: crops
                            });
                        } else {
                            consoleLogger(["SearchForm:getCropData", response]);
                        }
                    })
                    .catch(function (error) {
                        consoleLogger(["SearchForm:getCropOptions", error]);
                    });
            }
        }
        else if (appState.searchBy === 'pest') {
            if (appStateCropPest.pests.length > 0) {
                setAppState({
                    ...appState,
                    list: appStateCropPest.pests
                });
            }
            else {
                dataServices
                    .getPest(
                        country,
                        lng,
                        appState.value === null ? null : appState.value.value,
                        settings,
                        '',
                        id,
                        null,
                        false
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            const data = response.data;
                            const pests = data.map(function (item) {
                                return {
                                    value: item.id,
                                    label: item.name,
                                    label_en: item.nameEN,
                                };
                            });

                            setAppStateCropPest({
                                ...appStateCropPest,
                                pests: pests
                            });

                            setAppState({
                                ...appState,
                                list: pests
                            });
                        } else {
                            consoleLogger(["SearchForm:getPest", response]);
                        }

                    })
                    .catch(function (error) {
                        consoleLogger(["SearchForm:getPest", error]);
                    });
            }
        }
    }

    const onSearchByChange = function (val) {

        if (val === appState.searchBy) return;

        setAppState({
            ...appState,
            data: [],
            list: [],
            searchBy: val,
            value: null
        });

        setShowMoreState(true);
    };

    const getCropPestData = () => {
        if (appState.value != null && (appState.searchBy === 'crop' || appState.searchBy === 'pest')) {
            dataServices
                .getCropsPests(country
                    , lng
                    , id
                    , appState.searchBy === 'crop' ? appState.value.value : ''
                    , appState.searchBy === 'pest' ? appState.value.value : ''
                    , 1
                    , appState.searchBy
                    , settings
                    , false)
                .then((response) => {
                    if (response.status === 200) {
                        var data = response.data.result ?? [];
                        var croppestlist = searchApplication.getCropPestTreeAsync(data);
                        setAppState({
                            ...appState,
                            data: searchApplication.splitTree(1, croppestlist),
                        });
                    } else {
                        consoleLogger(["CropPestViewAsync:useEffect:getCropsPests", response]);
                    }
                })
                .catch(function (error) {
                    consoleLogger(["CropPestViewAsync:useEffect:getCropsPests", error]);
                });
        }
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState.searchBy]);

    useEffect(() => {
        getCropPestData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState.value]);

    return (
        <div className="treeview-wrapper">
            <div className="row">
                <div className="col-sm-12">                    
                    <div className="list">
                        <div className="card-header">
                            <div className="mb-4 mt-4">
                                <h3 className="section-text">
                                    <Trans>SearchCropPest</Trans>
                                </h3>
                            </div>
                            <div className="form-row toggle-crop-pest">
                                <div className="o-switch d-flex d-row" data-toggle="buttons" role="group">
                                    <label onClick={() => { onSearchByChange('crop') }} className={appState.searchBy === 'crop' ? "btn p-info-btn active me-3" : "btn p-info-btn me-3" }>
                                        <IconCrop /> {t('SearchCrop')}
                                    </label>
                                    <label onClick={() => { onSearchByChange('pest') }} className={appState.searchBy === 'pest' ? "btn p-info-btn active me-3" : "btn p-info-btn me-3" }>
                                        <IconPest /> {t('SearchPest')}
                                    </label>
                                </div>
                            </div>
                            {appState.searchBy && <>
                                <div className="mt-3">
                                    {
                                        appState.searchBy === 'crop'
                                            ? <p className="section-text"><Trans>FindOtherCrops</Trans></p>
                                            : <p className="section-text"><Trans>FindOtherPests</Trans></p>
                                    }
                                </div>
                                <div className="mt-3">
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select-react"
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={false}
                                        isRtl={searchApplication.isRTL(i18n)}
                                        isSearchable={true}
                                        name="croppest"
                                        options={appState.list.sort(searchApplication.sortOnAsc('label'))}
                                        placeholder={appState.searchBy === "crop" ? t("Crop") : t("Pest")}
                                        value={appState.value}
                                        onChange={onValueChange}
                                        components={NoOptionsMessage}
                                    />
                                </div>
                            </>}
                        </div>
                        {appState.data && appState.data.length > 0 ? <div className="treeview-wrapper">
                            {appState.data.map((mainnode, j) => {
                                return (
                                    <div
                                        className="treeview-col"
                                        style={{ width: appState.data.length > 1 ? "33%" : "100%" }}
                                        key={j}
                                    >
                                        {mainnode.map((node, i) => {
                                            return (
                                                <TreeView
                                                    key={node.parent.id}
                                                    nodeLabel={treeNodeDetails(
                                                        node.parent,
                                                        appState.searchBy === 'crop' ? "C" : "P",
                                                        t
                                                    )}
                                                    defaultCollapsed={false}
                                                >
                                                    {searchApplication.sortCropPest(node.child, appState.searchBy === 'crop' ? "P" : "C").map((child, j) => {
                                                        return (
                                                            <div className={j >= maxChildToShow && showMoreState ? 'd-none' : ''} key={node.parent.id + child.id}>
                                                                {treeNodeDetails(
                                                                    child,
                                                                    appState.searchBy === 'crop' ? "P" : "C",
                                                                    t
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                    <div key={node.parent.id + 'showmore'} className={showMoreState && node.child.length > maxChildToShow ? 'showmore' : 'showmore d-none'}>
                                                        <span className="more" onClick={() => { setShowMoreState(false); }}><Trans>ShowMore</Trans></span>
                                                    </div>
                                                </TreeView>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div> : null}
                    </div>
                    <div className="line-break mt-3"></div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(OtherPermittedUses);

