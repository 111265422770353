import React, { useState, useEffect } from "react";
import TreeView from "react-treeview";
import { Trans } from "react-i18next";

import { treeNodeDetails } from "./TreeNodeDetails.js";
import { SettingsContext } from "../settingsContext";
import { dataServices } from "../../services/dataServices";
import { searchApplication, consoleLogger } from "../../services/utilities";

/**
 * Component to render crop/pest tree view for permitted uses
 * @param {Array} List of crop/pests
 * @returns Render crop/pest tree
 */
export function PermittedUses(props) {

    let { t, country, lng, id, crop, pest } = props;

    const settings = React.useContext(SettingsContext);

    /** Default open with crop view */
    const [appState, setAppState] = useState({
        dataSource: null,
        data: [],
        state: crop === '' && pest === '' ? false : (crop !== '' ? 'C' : 'P')
    });

    const [showMoreState, setShowMoreState] = useState(true);

    const maxChildToShow = 9;

    const getData = () => {
        dataServices
            .getCropsPests(country, lng, id, crop, pest, 1, crop ? 'crop' : 'pest', settings)
            .then((response) => {
                if (response.status === 200) {
                    var data = response.data.result ?? [];
                    var croppestlist = searchApplication.getCropPestTreeAsync(data);
                    setAppState({
                        ...appState,
                        data: data,
                        dataSource: searchApplication.splitTree(1, croppestlist),
                        loadMore: false
                    });
                } else {
                    consoleLogger(["CropPestViewAsync:useEffect:getCropsPests", response]);
                }
            })
            .catch(function (error) {
                consoleLogger(["CropPestViewAsync:useEffect:getCropsPests", error]);
            });
    };

    useEffect(() => {
        if (appState.state) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        appState.state === 'C' || appState.state === 'P' ? <div className="treeview-wrapper">
            <div className="row">
                <div className="col-sm-12">
                    <h3 className="header-section-label fw-bold mt-4">
                        <Trans>ProductPermittedUses</Trans>
                    </h3>
                    <div className="list">
                        {appState.dataSource ? appState.dataSource.map((mainnode, j) => {
                            return <div
                                className="treeview-col"
                                style={{ width: appState.dataSource.length > 1 ? "33%" : "100%" }}
                                key={j}
                            >
                                {mainnode.map((node, i) => {
                                    return (
                                        <TreeView
                                            key={node.parent.id}
                                            nodeLabel={treeNodeDetails(
                                                node.parent,
                                                appState.state,
                                                t
                                            )}
                                            defaultCollapsed={false}
                                        >
                                            {searchApplication.sortCropPest(node.child, appState.state === 'C' ? 'P' : 'C').map((child, j) => {
                                                return (
                                                    <div className={j >= maxChildToShow && showMoreState ? 'd-none' : ''} key={node.parent.id + child.id}>
                                                        {treeNodeDetails(
                                                            child,
                                                            appState.state === 'C' ? 'P' : 'C',
                                                            t
                                                        )}
                                                    </div>
                                                );
                                            })}
                                            <div key={node.parent.id + 'showmore'} className={showMoreState && node.child.length > maxChildToShow ? 'showmore' : 'showmore d-none'}>
                                                <span className="more" onClick={() => { setShowMoreState(false); }}><Trans>ShowMore</Trans></span>
                                            </div>
                                        </TreeView>
                                    );
                                })}
                            </div>
                        }) : <div className="loading-ui-spinner"></div>}
                    </div>                    
                </div>
            </div>
        </div> : null
    );
}
